import * as React from "react"
import { graphql } from "gatsby"
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/solid'
import noImage from '../images/profiles/noimage.png';

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProfileTemplate = ({
  data: { site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`
  const pages = [
    { name: 'Search', href: '/search', current: false },
    { name: 'Profile', href: '', current: true },
  ]

  const isValidImage = (imagePath) => {
    return imagePath && /\.(jpg|jpeg|png|gif)$/i.test(imagePath);
  };

  const renderSocialLinks = (links) => {
    if (!links) {
      return null;
    }
  
    // List of allowed domains
    const allowedDomains = ['.com', '.net', '.org', /* add other domains as needed */];
  
    const linksArray = typeof links === 'string' ? links.split(' ').filter(link => link.trim() !== '') : links;
  
    // Filter and process the links
    const processedLinks = linksArray.map(link => {
      try {
        link = link.toLowerCase();
        if (!link.startsWith('http://') && !link.startsWith('https://')) {
          link = 'https://' + link;
        }
  
        const url = new URL(link);
        const isAllowedDomain = allowedDomains.some(domain => url.hostname.includes(domain));
        if (!isAllowedDomain) {
          return null;
        }
  
        const domainParts = url.hostname.split('.');
        const siteName = domainParts.length > 2 ? domainParts[1] : domainParts[0];
        const formattedName = siteName.charAt(0).toUpperCase() + siteName.slice(1);
  
        return { link, formattedName };
      } catch (error) {
        return null;
      }
    }).filter(linkObj => linkObj !== null);
  
    // Check if there are any valid links to render
    if (processedLinks.length === 0) {
      return null;
    }
  
    return (
      <>
        <ul className="list-none">
          {processedLinks.map((linkObj, index) => (
            <li key={index}>
              <a href={linkObj.link} title={linkObj.formattedName} target="_blank" rel="noopener noreferrer">
                {linkObj.formattedName}
              </a>
            </li>
          ))}
        </ul>
      </>
    );
  };
  
  
  
  
  
  
  return (
    <Layout location={location} title={siteTitle}>

        <div className="bg-gray-900 p-5 mx-1 md:py-5">
          <div className="container mx-auto">
            <nav className="flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-3">
                <li>
                  <div>
                    <a href="/" className="text-gray-400 hover:text-white">
                      <HomeIcon className="h-3 w-3 flex-shrink-0" aria-hidden="true" />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                {pages.map((page) => (
                  <li key={page.name}>
                    <div className="flex items-center">
                      <ChevronRightIcon className="h-3 w-3 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <a
                        href={page.href}
                        className="ml-4 text-xs font-medium text-gray-500 hover:text-white"
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>

        <div className="container mx-auto p-5 md:p-10 md:rounded-md mb-5 text-white bg-gray-800 text-sm md:my-5">
          

            <div className="bg-slate-800 grid grid-cols-1 md:grid-cols-4">

              <div className="">
                <div className="pr-5">
                <img 
                  src={post.frontmatter.profile_img || noImage} 
                  alt={`${post.frontmatter.name} Profile`} 
                  className="w-64 rounded-md mb-5" 
                  onError={(e) => {
                    e.target.onerror = null; // Prevent looping
                    e.target.src = noImage;
                  }}
                />
                  <div className="mb-2">{post.frontmatter.pronoun}</div>
                  <div className="text-lg font-medium break-words overflow-hidden">{post.frontmatter.tagline}</div>
                  <div className="text-md mb-2">{post.frontmatter.status}</div>
                </div>
              </div>

              <div className="md:col-span-3">
              <h1 className="mt-5 md:mt-0 text-xl mb-5 font-semibold">Crew Profile Information</h1>
                <div className="border-t border-gray-700">
                  <dl className="divide-y divide-gray-700">
                    <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg md:text-sm font-bold">Full name</dt>
                      <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0">
                        <h1 className="text-md font-semibold">{post.frontmatter.name}</h1>
                      </dd>
                    </div>
                    
                    {post.frontmatter.professional_professions &&
                    <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg md:text-sm font-bold">Professions</dt>
                      <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0"> {post.frontmatter.professional_professions}</dd>
                    </div>
                    }

                    {post.frontmatter.professional_projects &&
                    <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg md:text-sm font-bold">Projects</dt>
                      <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0">{post.frontmatter.professional_projects}</dd>
                    </div>
                    } 
                    
                    {post.frontmatter.professional_genres &&
                    <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg md:text-sm font-bold">Genres</dt>
                      <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0">{post.frontmatter.professional_genres}</dd>
                    </div>
                    }

                    {post.frontmatter.social_links &&
                    <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-lg md:text-sm font-bold">Links</dt>
                      <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0">{renderSocialLinks(post.frontmatter.social_links)}</dd>
                    </div>
                    }

                    {post.html &&  
                      <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-lg md:text-sm font-bold">About</dt>
                        <dd className="mt-1 text-sm/6 sm:col-span-2 sm:mt-0">
                          <div
                            className="text-sm profile-details"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                          />
                        </dd>
                      </div>
                    }
                    
                  </dl>
                </div>
              </div>

            </div>

        </div>

        <div className="px-5 md:px-0 text-gray-500 text-xs container mx-auto">
          <p>This listing was automatically created by CREWZIP. This listing is not currently maintained by, endorsed by or affiliated with {post.frontmatter.name}.</p>
          <p>{post.frontmatter.name} is an unclaimed page. Only {post.frontmatter.name} may claim this page.</p>
        </div>

        
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={`${post.frontmatter.name} - ${post.frontmatter.status}`}
    />
  )
}

export default ProfileTemplate

export const query = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }, fileAbsolutePath: { regex: "/content/profile/" }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        name
        date(formatString: "MMMM DD, YYYY")
        tagline
        pronoun
        status
        professional_professions
        professional_projects
        professional_genres
        social_links
        profile_img
      }
    }
  }
`

